import styled, { css } from "styled-components"
import { useNatalSunMapStore } from "../helpers/store";
import { FooterContainer, StyledButton } from './Form';
import { PaymentData } from "../../finance-capacity/helpers/types";
import { getPaymentResult, hash } from "../../finance-capacity/helpers/api";
import { cloudPayments, getUTMCommentForCP } from "../../../helpers/cloudPayments";
import { SunInterpretation } from "../helpers/types";
import { googleEvent } from "../../../helpers/metrics";

const BLUR_TEXT = 'Данный фрагмент текста доступен при покупке полного разбора Вашей натальной карты.'

export default function NatalMapSunResult({
    token
}: {
    token: string
}) {

  const isExternal = token === 'natal_map_sun';
    
  const {mapImage, interpretation, data, setMapImage, setInterpretation, setData} = useNatalSunMapStore();

  const formData = useNatalSunMapStore((state) => state.data);
  // todo get from store/props
  const storeToken = 'edd9f8ce3df85b4069f5ff67efeb';
  const onBack = () => {
    setMapImage(undefined);
    setInterpretation(undefined);
    setData(undefined);
  };

  // todo rename and refactor, я тут просто накидал, чтобы поднялось :)
  const goToFull: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    if(isExternal) {
        window.location.href = "https://p.chronos.ru/natal-chart-cons";
        return;
    }
      // @ts-ignore // fix-me, add name
      const data: PaymentData = {
          ...formData,
          partner: '',
          token: storeToken,
          promo : '',
          country: 'ru',
          natalMapSun: true,
          subtype: 'natalMapSun',
          // captchaToken
      };

      // todo вынести в api
      getPaymentResult(data, (log) => { console.log(log) }).then(async (result) => {
          if (result) {
              const CustomerReceipt = { ...result.receipt, email: formData?.email ?? '' };
              data.type = 'widget';
              data.cloudPayments = { CustomerReceipt };
              data.createdAt = Date.now();
              data.K8S_NAMESPACE = result.K8S_NAMESPACE;
              data.operation = await hash(JSON.stringify(data));
              data.comment = getUTMCommentForCP();

              Object.assign(result.options, {
                  InvoiceId: storeToken,
                  data,
                  email: formData?.email ?? '',
                  requireEmail: true,
                  skin: 'mini'
              });

              const success = (smtg: any) => { 
                googleEvent('payment_natal_successful');
                const site = `${window.location.origin}${window.location.pathname}?${window.location.search.replace('?', '')}`;
                const url = `${site}&date=${data.date}&time=${data.time}&place=${data.place}&email=${data.email}&lat=${data.lat}&lon=${data.lon}#natal_map_sun_full`;
                window.open(url, '_blank');
                //window.location.href = ;
              };
              const cancel = (smtg: any) => { console.log(smtg) };

              const cp = async () => {
                  try {
                      const cp = await cloudPayments();
                      new cp().charge(result.options, success, cancel);
                  } catch (error) {

                  }
              };
              cp();
          }
      })
  };

  const goToFullWithAnalyticsByBanner: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    goToFull(e);
    googleEvent('widget_natal_click_pay_banner');
  }

  const goToFullWithAnalyticsByButton: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    goToFull(e);
    googleEvent('widget_natal_click_pay_button');
  }

  const goToIndi: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    googleEvent('widget_natal_click_trial');
    setTimeout(() => { window.location.href = 'https://indi.chronos.mg/trial?d=14&utm_source=natal'; }, 500 );
  }

  

  //@ts-ignore
  const interpretationArray: SunInterpretation[] = Array.isArray(interpretation) ? interpretation : [interpretation];

  const bannerFull = `https://widget.chronos.mg/${require('../assets/img/full-banner.jpg')}`;
  const bannerFree = `https://widget.chronos.mg/${require('../assets/img/free-banner.jpg')}`;

  return <Container>
      {!data?.isFull && <Header><BackLink onClick={onBack}>← Поменять данные</BackLink></Header> }
      <img src={mapImage} alt="map" />
      <Interpretation>
        <Title>Описание личности на основе натальной карты</Title>
        <UserInfo>
            {data?.name}&nbsp;&nbsp;&nbsp;
            {data?.date}&nbsp;&nbsp;&nbsp;
            {data?.time.slice(0, 5)}&nbsp;&nbsp;&nbsp;
            {data?.place}
        </UserInfo>
        
        {!data?.isFull && <Info>
            <span>Доступно описание личности по Солнцу</span>
            В полной версии у вас будет описание по Луне, Венере, Меркурию и другим планетам. 
        </Info>}

        {interpretationArray.map((interpretation, i ) => <>
        
            {[interpretation?.sign, interpretation?.house].map((item) => {
                const isBlurred = item.text === 'blurred';
                const text = !isBlurred ? item.text : new Array(7).fill(BLUR_TEXT).join(' ');
                return <> {text && <Block >
                        <h2>{item.title}</h2>
                        <Text dangerouslySetInnerHTML={{__html: text || ''}} blurred={isBlurred}></Text>
                        <Facts blurred={isBlurred}>
                            {item.facts.map(item => {
                                const text = !isBlurred ? item.text : new Array(2).fill(BLUR_TEXT).join(' ');
                                return <Fact>
                                    <FactIcon>{item.icon}</FactIcon>
                                    <FactText>{text}</FactText>
                                </Fact>
                            })}
                        </Facts>
                    </Block>} </>
                }
            )}
            
            

            {i % 3 === 0  && !isExternal && !data?.isFull && <a href="/" onClick={goToFullWithAnalyticsByBanner} target="_blank" rel="noreferrer" >
                <img style={{width: "100%", display: 'block', marginBottom: '1rem'}} src={bannerFree} alt="banner" />
            </a>}
            {i % 2 === 0  && !isExternal && data?.isFull && <a href="/" onClick={goToIndi} target="_blank" rel="noreferrer" >
                <img style={{width: "100%", display: 'block', marginBottom: '1rem'}} src={bannerFull} alt="banner" />
            </a>}

        </>)}
        
        
      </Interpretation>
      
      {!data?.isFull && <Footer>
        <FooterContainer>
          <StyledButton
            name="submit-button"
            color="blue"
            onClick={goToFullWithAnalyticsByButton}
          >
            {isExternal ? 
                'Купить разбор натальной карты со скидкой' :
                'Купить полный разбор натальной карты за 399 ₽' 
            }
          </StyledButton>
        </FooterContainer>
        
      </Footer>}

  </Container>
}

const Container = styled.div`
    width: 42.5rem;
    max-width: 85%;
    padding: 1rem 0 6rem;
    margin: 0 auto;

    > img {
        max-width: 100%;
    }
`

const Header = styled.div`
    margin-bottom: 1rem;
    text-align: left;
`

const BackLink = styled.span`
    color: var(--text-secondary);
    cursor: pointer;
`

const Interpretation = styled.div`
    text-align: left;
    text-wrap: balance;
    margin-top: 1rem;
`

const Title = styled.div`
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.25rem;
    color: var(--text-secondary);
    margin-bottom: 0.25rem;
`

const UserInfo = styled.div`
    font-family: Inter;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: var(--text-third);
    margin-bottom: 0.75rem;
`

const Info = styled.div`
    margin-bottom: 2rem;
    background: rgba(64, 147, 244, 0.04);
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--text-secondary);

    > span {
        display: block;
        color: var(--text-primary);
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        text-align: left;
        margin-bottom: 0.125rem;
    }

`

const Block = styled.div`
    margin-bottom: 2rem;
    color: var(--text-secondary);
    line-height: 1.5rem;

    > h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        margin-bottom: 0.5rem;
    }
`

const Text = styled.div<{blurred: boolean}>`
    font-size: 1rem;
    margin-bottom: 1.25rem;
     
    ${p => p.blurred && css`
        user-select: none;
        filter: blur(4px);
        -webkit-filter: blur(4px);
    `}
`

const Facts = styled.div<{blurred: boolean}>`
    display: grid;
    grid-gap: 0.75rem;
    grid-template-columns: 1fr 1fr;
    
    @media (max-width: 460px) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
    }
     
    ${p => p.blurred && css`
        user-select: none;
        filter: blur(4px);
        -webkit-filter: blur(4px);
    `}
`

const Fact = styled.div`
    background: var(--color-white);
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.02);
    border-radius: 1rem;
`

const FactIcon = styled.div`

`

const FactText = styled.div`

`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray-color);
  font-size: 1rem;

  background: var(--white-color);
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border-top: 1px solid var(--black-light-color);

  @media (max-width: 768px){
    button {
        font-size: 0.75rem;
    }
  }
`;